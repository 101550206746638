<template>
  <div>
    <v-row justify="center">
      <v-col md="6">
        <h3 class="mt-2 mb-0 pb-0 mx-3">Upcoming Events</h3>
        <intract-smart-list
          ref="upcoming-events-posts-smart-list"
          key="upcoming-events-posts-smart-list"
          class="mt-2"
          paginated
          disable-search
          no-spacing
          :endpoint="eventPostsEndpoint"
          no-more-data-text=""
          :item-options="{ title: 'title' }"
          @updateAllItemsList="(list) => (eventPosts = list)"
        >
          <template v-slot:loading-component>
            <div class="mx-4">
              <v-skeleton-loader
                type="card"
                class="mt-2"
                elevation="1"
              ></v-skeleton-loader>
            </div>
          </template>
          <template v-slot:list-item="{ item: post }">
            <v-card
              @click="
                $router.push({
                  name: 'ViewEventPost',
                  params: { postId: post.id },
                })
              "
              class="mt-2 post-card mx-3 pb-2 pt-0"
            >
              <v-img
                v-if="post.image_file"
                :src="post.image_file['document']"
                height="180px"
                class="mt-1"
              ></v-img>

              <v-card-title class="py-0 my-1"
                ><h5 class="mt-1">
                  {{ post.title }}
                </h5>
              </v-card-title>

              <v-card-subtitle class="pb-0 pt-2">
                {{ post.description }}
              </v-card-subtitle>

              <v-card-text class="pb-1 text-caption">
                <span
                  v-if="
                    moment(post.start_datetime).date() ==
                    moment(post.end_datetime).date()
                  "
                  >{{ moment(post.start_datetime).format("lll") }} -
                  {{ moment(post.end_datetime).format("hh:mm A") }}</span
                ><span v-else
                  >{{ moment(post.start_datetime).format("lll") }} -
                  {{ moment(post.end_datetime).format("lll") }}</span
                >
              </v-card-text>
            </v-card>
          </template>
        </intract-smart-list>
        <div class="text-center" v-if="!showPastEvents && !isLoading">
          <v-btn text color="primary" @click="showPastEvents = true"
            >Show past events</v-btn
          >
        </div>
        <div v-if="showPastEvents">
          <h3 class="mt-n2 mx-3">Past Events</h3>
          <intract-smart-list
            ref="past-events-posts-smart-list"
            key="past-events-posts-smart-list"
            paginated
            disable-search
            :endpoint="pastEventPostsEndpoint"
            :item-options="{ title: 'title' }"
            @updateAllItemsList="(list) => (eventPosts = list)"
          >
            <template v-slot:loading-component>
              <div class="mx-4">
                <v-skeleton-loader
                  type="card"
                  class="mt-2"
                  elevation="1"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="card"
                  class="mt-2"
                  elevation="1"
                ></v-skeleton-loader>
              </div>
            </template>
            <template v-slot:list-item="{ item: post }">
              <v-card
                @click="
                  $router.push({
                    name: 'ViewEventPost',
                    params: { postId: post.id },
                  })
                "
                class="mt-2 post-card mx-3 pb-2 pt-0"
              >
                <v-img
                  v-if="post.image_file"
                  :src="post.image_file['document']"
                  height="180px"
                  class="mt-1"
                ></v-img>

                <v-card-title class="py-0 mb-1"
                  ><h5 class="mt-1">
                    {{ post.title }}
                  </h5>
                </v-card-title>

                <v-card-subtitle class="pb-0 pt-2">
                  {{ post.description }}
                </v-card-subtitle>

                <v-card-text class="pb-1 text-caption">
                  <span
                    v-if="
                      moment(post.start_datetime).date() ==
                      moment(post.end_datetime).date()
                    "
                    >{{ moment(post.start_datetime).format("lll") }} -
                    {{ moment(post.end_datetime).format("hh:mm A") }}</span
                  ><span v-else
                    >{{ moment(post.start_datetime).format("lll") }} -
                    {{ moment(post.end_datetime).format("lll") }}</span
                  >
                </v-card-text>
              </v-card>
            </template>
          </intract-smart-list>
        </div>
      </v-col>
    </v-row>

    <v-fab-transition v-if="currentUser.is_admin && !isLoading">
      <v-btn
        color="primary"
        @click="postCreateEditSheet.visible = true"
        dark
        bottom
        right
        fixed
        fab
        elevation="0"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Create Event"
      description="Organize and manage events in your institution!"
      :visible="postCreateEditSheet.visible"
      @close="postCreateEditSheet.visible = false; postCreateEditSheet.editId = null;"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.eventsViewSet"
      :edit-id="postCreateEditSheet.editId"
      :data-object="postCreateEditSheet.obj"
      :create-success-message="postCreateEditSheet.createSuccessMessage"
      @objectCreated="eventCreated"
      @updateObject="(obj) => (postCreateEditSheet.obj = obj)"
    />
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";

export default {
  name: "AllEventsPosts",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: { IntractSmartList, IntractCreateEditSheet },
  data() {
    return {
      moment: moment,
      currentDate: Date.now(),
      showPastEvents: false,
      postCreateEditSheet: {
        visible: false,
        createSuccessMessage: "Event created successfully!",
        editId: null,
        obj: {
          created_by: null,
          uploader: null,
          institution: null,
        },
      },
    };
  },
  computed: {
    eventPostsEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.eventsViewSet, [
        "ordering=start_datetime",
        "end_datetime__gte=" + moment(this.currentDate).format(this.Helper.dateURLFormat),
      ]);
      return url;
    },
    pastEventPostsEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.eventsViewSet, [
        "ordering=-start_datetime",
        "end_datetime__lte=" + moment(this.currentDate).format(this.Helper.dateURLFormat),
      ]);
      return url;
    },
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 6,
          max: 255,
        },
        venue: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Venue",
          required: true,
          md: 6,
          max: 255,
        },

        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        start_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.postCreateEditSheet.obj.end_datetime ||
              moment(this.postCreateEditSheet.obj.end_datetime).isAfter(
                moment(this.postCreateEditSheet.obj.start_datetime)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.postCreateEditSheet.obj.start_datetime).isAfter(
                moment()
              ) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        end_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.postCreateEditSheet.obj.start_datetime == null,
          min: new Date(this.postCreateEditSheet.obj.start_datetime),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.postCreateEditSheet.obj.end_datetime).isAfter(
                moment(this.postCreateEditSheet.obj.start_datetime)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
        },

        image_file: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: false,
          label: "Image (Optional)",
          helper: "JPG, PNG, GIF, etc",
        },
      };
    },
  },
  methods: {
    async eventCreated(obj) {
      await this.$refs['upcoming-events-posts-smart-list'].getItems();
      this.$router.push({ name: 'ViewEventPost', params: {postId: obj.id} });
    },
    async onRefresh() {
      this.currentDate = Date.now(); // used to recompute the computed endpoint function
      this.$refs["upcoming-events-posts-smart-list"].getItems();
      this.showPastEvents = false;
    },
    setListeners() {
      EventBus.$on("event__post_deleted", (id) => {
        if (this.$refs["upcoming-events-posts-smart-list"])
          this.$refs["upcoming-events-posts-smart-list"].removeItem(id);
        if (this.$refs["past-events-posts-smart-list"] && this.showPastEvents)
          this.$refs["past-events-posts-smart-list"].removeItem(id);
      });

      EventBus.$on("event__post_updated", (postObject) => {
        if (this.$refs["upcoming-events-posts-smart-list"])
          this.$refs["upcoming-events-posts-smart-list"].replaceItem(
            postObject.id,
            postObject
          );
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "AllEventsPosts");
    }
    next();
  },
  created() {
    this.postCreateEditSheet.obj.uploader = this.currentUser.id;
    this.postCreateEditSheet.obj.institution = this.currentInstitution.id;
    this.postCreateEditSheet.obj.created_by = this.currentUser.id;
    this.setListeners();
  },
};
</script>
<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>





