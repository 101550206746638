var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('h3',{staticClass:"mt-2 mb-0 pb-0 mx-3"},[_vm._v("Upcoming Events")]),_c('intract-smart-list',{key:"upcoming-events-posts-smart-list",ref:"upcoming-events-posts-smart-list",staticClass:"mt-2",attrs:{"paginated":"","disable-search":"","no-spacing":"","endpoint":_vm.eventPostsEndpoint,"no-more-data-text":"","item-options":{ title: 'title' }},on:{"updateAllItemsList":(list) => (_vm.eventPosts = list)},scopedSlots:_vm._u([{key:"loading-component",fn:function(){return [_c('div',{staticClass:"mx-4"},[_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"card","elevation":"1"}})],1)]},proxy:true},{key:"list-item",fn:function({ item: post }){return [_c('v-card',{staticClass:"mt-2 post-card mx-3 pb-2 pt-0",on:{"click":function($event){return _vm.$router.push({
                name: 'ViewEventPost',
                params: { postId: post.id },
              })}}},[(post.image_file)?_c('v-img',{staticClass:"mt-1",attrs:{"src":post.image_file['document'],"height":"180px"}}):_vm._e(),_c('v-card-title',{staticClass:"py-0 my-1"},[_c('h5',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(post.title)+" ")])]),_c('v-card-subtitle',{staticClass:"pb-0 pt-2"},[_vm._v(" "+_vm._s(post.description)+" ")]),_c('v-card-text',{staticClass:"pb-1 text-caption"},[(
                  _vm.moment(post.start_datetime).date() ==
                  _vm.moment(post.end_datetime).date()
                )?_c('span',[_vm._v(_vm._s(_vm.moment(post.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(post.end_datetime).format("hh:mm A")))]):_c('span',[_vm._v(_vm._s(_vm.moment(post.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(post.end_datetime).format("lll")))])])],1)]}}])}),(!_vm.showPastEvents && !_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showPastEvents = true}}},[_vm._v("Show past events")])],1):_vm._e(),(_vm.showPastEvents)?_c('div',[_c('h3',{staticClass:"mt-n2 mx-3"},[_vm._v("Past Events")]),_c('intract-smart-list',{key:"past-events-posts-smart-list",ref:"past-events-posts-smart-list",attrs:{"paginated":"","disable-search":"","endpoint":_vm.pastEventPostsEndpoint,"item-options":{ title: 'title' }},on:{"updateAllItemsList":(list) => (_vm.eventPosts = list)},scopedSlots:_vm._u([{key:"loading-component",fn:function(){return [_c('div',{staticClass:"mx-4"},[_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"card","elevation":"1"}}),_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"card","elevation":"1"}})],1)]},proxy:true},{key:"list-item",fn:function({ item: post }){return [_c('v-card',{staticClass:"mt-2 post-card mx-3 pb-2 pt-0",on:{"click":function($event){return _vm.$router.push({
                  name: 'ViewEventPost',
                  params: { postId: post.id },
                })}}},[(post.image_file)?_c('v-img',{staticClass:"mt-1",attrs:{"src":post.image_file['document'],"height":"180px"}}):_vm._e(),_c('v-card-title',{staticClass:"py-0 mb-1"},[_c('h5',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(post.title)+" ")])]),_c('v-card-subtitle',{staticClass:"pb-0 pt-2"},[_vm._v(" "+_vm._s(post.description)+" ")]),_c('v-card-text',{staticClass:"pb-1 text-caption"},[(
                    _vm.moment(post.start_datetime).date() ==
                    _vm.moment(post.end_datetime).date()
                  )?_c('span',[_vm._v(_vm._s(_vm.moment(post.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(post.end_datetime).format("hh:mm A")))]):_c('span',[_vm._v(_vm._s(_vm.moment(post.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(post.end_datetime).format("lll")))])])],1)]}}],null,false,1187388506)})],1):_vm._e()],1)],1),(_vm.currentUser.is_admin && !_vm.isLoading)?_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"primary","dark":"","bottom":"","right":"","fixed":"","fab":"","elevation":"0"},on:{"click":function($event){_vm.postCreateEditSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e(),(_vm.currentUser.is_admin)?_c('intract-create-edit-sheet',{attrs:{"persistent":"","title":"Create Event","description":"Organize and manage events in your institution!","visible":_vm.postCreateEditSheet.visible,"fields":_vm.formFields,"enable-media":"","endpoint":_vm.endpoints.eventsViewSet,"edit-id":_vm.postCreateEditSheet.editId,"data-object":_vm.postCreateEditSheet.obj,"create-success-message":_vm.postCreateEditSheet.createSuccessMessage},on:{"close":function($event){_vm.postCreateEditSheet.visible = false; _vm.postCreateEditSheet.editId = null;},"objectCreated":_vm.eventCreated,"updateObject":(obj) => (_vm.postCreateEditSheet.obj = obj)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }